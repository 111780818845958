<script setup lang="ts">
import { NegotiationState } from '~/apiClient';
import type { AuctionItemEntityWithNegotiation } from '~/types/auction.type';

// PROPS
const { auction } = defineProps({
    auction: { type: Object as PropType<AuctionItemEntityWithNegotiation>, required: true },
});

// MODEL
const isModalOpen = defineModel<boolean>({ required: true });

// COMPOSABLES
const { isMobile } = useResponsive();
const { tt } = useTypedI18n();
const { negotiation, sellerDecline, sellerDeclineIsPending } = useNegotiation(() => auction);

// METHODS
const closeModal = (): void => {
    isModalOpen.value = false;
};

const onConfirm = async (): Promise<void> => {
    try {
        await sellerDecline();
        closeModal();
    } catch {}
};

// WATCHERS
watch(
    () => negotiation.value.state,
    value => isModalOpen.value && value !== NegotiationState.Open && closeModal()
);
</script>

<template>
    <UModal v-model="isModalOpen" :ui="{ width: 'md:max-w-md' }" :fullscreen="isMobile">
        <div class="space-y-7 p-6 text-center">
            <div class="space-y-2">
                <p class="text-2xl font-bold lg:text-3xl">
                    {{ tt('negotiation.sellerDeclineModal.title') }}
                </p>
                <p class="text-xs text-neutral-500 lg:text-sm">
                    {{ tt('negotiation.sellerDeclineModal.disclaimer') }}
                </p>
            </div>

            <div class="flex flex-col items-center gap-1">
                <UButton
                    size="xl"
                    color="red"
                    :label="tt('negotiation.sellerDeclineModal.confirmButton')"
                    block
                    :loading="sellerDeclineIsPending"
                    @click="onConfirm"
                />
                <UButton size="xl" class="underline" color="white" variant="link" :label="tt('negotiation.cancel')" @click="closeModal" />
            </div>
        </div>
    </UModal>
</template>
