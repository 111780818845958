<script setup lang="ts">
import { countries } from '@lib/shared';
import lodashSortBy from 'lodash-es/sortBy';
import lodashToNumber from 'lodash-es/toNumber';
import lodashToString from 'lodash-es/toString';

import { AssetStateOfUse, type AuctionAssetBusEntity, type AuctionItemEntity } from '~/apiClient';
import type { AssetSpecItem, AssetSpecsGroup, AssetSpecsSection } from '~/types/assetSpecs.type';

// PROPS
const { auction, assetId } = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity>, required: true },
    assetId: { type: String, default: undefined },
});

// COMPOSABLES
const { tt, formatUnits, td } = useTypedI18n();
const { locale } = useTypedI18n();
const { isBundleMono } = useAuctionType(() => auction);

// DATA
const asset = computed<AuctionAssetBusEntity>(() => (assetId ? auction.assets.find(_a => _a.id === assetId)! : auction.mainAsset));
const sortedAssets = computed<AuctionAssetBusEntity[]>(() => sortAuctionAssets(auction));
const bundleSpecs = computed<AssetSpecsSection>(() =>
    sortedAssets.value.map(_a => [
        ['asset.firstRegistration', td(_a.firstRegistration, 'mmyyyy')],
        ['asset.kilometers', formatUnits.km(_a.km)],
        ['asset.vehicleCondition.base', tt(`asset.vehicleCondition.${_a.vehicleCondition}`)],
        ...insertIf<AssetSpecItem>(!!_a.useType, () => ['asset.useType.base', tt(`asset.useType.${_a.useType!}`)]),
        ['asset.vin', _a.vin],
        ['asset.inspectionExpiryDate', td(_a.inspectionExpiryDate, 'mmyyyy')],
        ['asset.registrationDocumentCountry', getCountryTranslation(_a)],
    ])
);
const descriptionSpecs = computed<AssetSpecsSection>(() => [
    ...insertIf<AssetSpecsGroup>(
        isBundleMono.value,
        [
            ['asset.antiPollution', tt(`asset.euroNorm.${asset.value.euroNorm}`)],
            ['asset.class.base', asset.value.class.map(busClass => tt(`asset.class.${busClass}`)).join(', ')],
            ['asset.version', asset.value.version.versionValue],
            ['asset.geographicalLocation', asset.value.geographicalLocation],
            [
                'asset.visitDate.base',
                asset.value.visitDateFrom && asset.value.visitDateTo
                    ? tt('asset.visitDate.fromTo', {
                          date: td(asset.value.visitDateFrom, 'ddmmyyyy'),
                          fromTime: td(removeTimezone(asset.value.visitDateFrom), 'hh'),
                          toTime: td(removeTimezone(asset.value.visitDateTo), 'hh'),
                      })
                    : null,
            ],
        ],
        [
            ['asset.converter', asset.value.bodyBuilder],
            ['asset.deckType.base', tt(`asset.deckType.${asset.value.deckType}`)],
            ['asset.length', formatUnits.meters(asset.value.length)],
            ['asset.width', formatUnits.meters(asset.value.width)],
            ['asset.height', formatUnits.meters(asset.value.height)],
        ]
    ),
    ...insertIf<AssetSpecsGroup>(
        !isBundleMono.value,
        [
            ['asset.firstRegistration', td(asset.value.firstRegistration, 'mmyyyy')],
            ['asset.kilometers', formatUnits.km(asset.value.km)],
            ['asset.antiPollution', tt(`asset.euroNorm.${asset.value.euroNorm}`)],
            ['asset.class.base', asset.value.class.map(busClass => tt(`asset.class.${busClass}`)).join(', ')],
            ['asset.version', asset.value.version.versionValue],
            ['asset.geographicalLocation', asset.value.geographicalLocation],
            [
                'asset.visitDate.base',
                asset.value.visitDateFrom && asset.value.visitDateTo
                    ? tt('asset.visitDate.fromTo', {
                          date: td(asset.value.visitDateFrom, 'ddmmyyyy'),
                          fromTime: td(removeTimezone(asset.value.visitDateFrom), 'hh'),
                          toTime: td(removeTimezone(asset.value.visitDateTo), 'hh'),
                      })
                    : null,
            ],
        ],
        [
            ['asset.vehicleCondition.base', tt(`asset.vehicleCondition.${asset.value.vehicleCondition}`)],
            ...insertIf<AssetSpecItem>(!!asset.value.useType, () => ['asset.useType.base', tt(`asset.useType.${asset.value.useType!}`)]),
            ['asset.vin', asset.value.vin],
            ['asset.converter', asset.value.bodyBuilder],
            ['asset.deckType.base', tt(`asset.deckType.${asset.value.deckType}`)],
            ['asset.length', formatUnits.meters(asset.value.length)],
            ['asset.width', formatUnits.meters(asset.value.width)],
            ['asset.height', formatUnits.meters(asset.value.height)],
            ['asset.inspectionExpiryDate', td(asset.value.inspectionExpiryDate, 'mmyyyy')],
            ['asset.registrationDocumentCountry', getCountryTranslation(asset.value)],
        ]
    ),
    [
        ['asset.doors.base', tt(`asset.doors.${asset.value.doors}`)],
        ['asset.totalCapacity', asset.value.totalCapacity],
        ['asset.seats', asset.value.seats],
        ['asset.seatsFromS1', asset.value.seatsFromS1],
        ['asset.standingPlaces', asset.value.standingPlaces],
        ['asset.standingPlacesFromS2', asset.value.standingPlacesFromS2],
        ['asset.companionSeats', asset.value.companionSeats],
        ['asset.foldingSeats', asset.value.foldingSeats],
        ['asset.wheelchairPlaces', asset.value.wheelchairPlaces],
        ...insertIf<AssetSpecItem>(!!asset.value.wheelchairRamp, () => [
            'asset.wheelchairRamp.base',
            tt(`asset.wheelchairRamp.${asset.value.wheelchairRamp!}`),
        ]),
    ],
    sortFalseLast([
        ['asset.luggageCapacity', formatUnits.cubicMeters(lodashToNumber(asset.value.luggageCapacity))],
        ['asset.passThroughLuggageCompartment', asset.value.passThroughLuggageCompartment],
    ]),
]);

const conditionsSpecs = computed<AssetSpecsSection>(() =>
    isBundleMono.value ? sortedAssets.value.map(_a => getConditionSpecs(_a)) : [getConditionSpecs(asset.value)]
);

const transmissionSpecs = computed<AssetSpecsSection>(() => [
    [
        ...insertIf<AssetSpecItem>(!!asset.value.gearboxType, () => ['asset.gearboxType.base', tt(`asset.gearboxType.${asset.value.gearboxType!}`)]),
        ['asset.gearboxBrand', asset.value.gearboxBrand],
        ['asset.gearboxModel', asset.value.gearboxModel],
    ],
]);

const engineSpecs = computed<AssetSpecsSection>(() => [
    [
        ['asset.fuel.base', tt(`asset.fuel.${asset.value.fuel}`)],
        ['asset.engineBrand', asset.value.engineBrand],
        ['asset.engineModel', asset.value.engineModel],
        ['asset.enginePosition', tt(`asset.position.${asset.value.enginePosition}`)],
    ],
    [
        ['asset.displacement', formatUnits.cc(asset.value.displacement)],
        ['asset.hp', asset.value.hp],
        ['asset.kw', formatUnits.kw(asset.value.kw)],
        ['asset.cylinders', asset.value.cylinders],
    ],
    [
        ['asset.fuelTankCapacity', formatUnits.liters(asset.value.fuelTankCapacity)],
        ['asset.gasTanks', asset.value.gasTanks],
        ...insertIf<AssetSpecItem>(!!asset.value.gasTanksMaterial, () => [
            'asset.gasTanksMaterial.base',
            tt(`asset.gasTanksMaterial.${asset.value.gasTanksMaterial!}`),
        ]),
        ['asset.batteryPower', formatUnits.kwh(asset.value.batteryPower)],
        ['asset.chargingType', asset.value.chargingType],
    ],
]);

const wheelsSpecs = computed<AssetSpecsSection>(() => [
    sortFalseLast([
        ['asset.axles', asset.value.axles],
        ['asset.wheels', asset.value.wheels],
        ['asset.wheelsSizeAxle1', asset.value.wheelsSizeAxle1],
        ['asset.wheelsSizeAxle2', asset.value.wheelsSizeAxle2],
        ['asset.wheelsSizeAxle3', asset.value.wheelsSizeAxle3],
        ['asset.spareWheel', asset.value.spareWheel],
        ['asset.alloyWheels', asset.value.alloyWheels],
    ]),
]);

const accessoriesSpecs = computed<AssetSpecsSection>(() => [
    sortFalseLast([
        ['asset.acDriver', asset.value.acDriver],
        ['asset.acPassengers', asset.value.acPassengers],
        ['asset.heater', asset.value.heater],
        ['asset.preHeater', asset.value.preHeater],
    ]),
    sortFalseLast([
        ['asset.cruiseControl.base', tt(`asset.cruiseControl.${asset.value.cruiseControl}`)],
        ['asset.tachograph.base', tt(`asset.tachograph.${asset.value.tachograph}`)],
        ['asset.abs', asset.value.abs],
        ['asset.asr', asset.value.asr],
        ['asset.retarder', asset.value.retarder],
        ['asset.breathalyzer', asset.value.breathalyzer],
    ]),
    sortFalseLast([
        ['asset.cctv', asset.value.cctv],
        ['asset.internalCameras', asset.value.internalCameras],
        ['asset.externalCameras', asset.value.externalCameras],
        ['asset.retrocamera', asset.value.retrocamera],
    ]),
    [
        ['asset.firePreventionSystem', asset.value.firePreventionSystem],
        ['asset.firePreventionSystemBrand', asset.value.firePreventionSystemBrand],
    ],
    [
        ['asset.indicatorSigns', asset.value.indicatorSigns],
        ['asset.indicatorSignsBrand', asset.value.indicatorSignsBrand],
    ],
    sortFalseLast([
        ['asset.driverBerth', asset.value.driverBerth],
        ['asset.socket220', asset.value.socket220],
    ]),
    sortFalseLast([
        ['asset.wifi', asset.value.wifi],
        ['asset.microphone', asset.value.microphone],
        ['asset.cdPlayer', asset.value.cdPlayer],
        ['asset.dvdPlayer', asset.value.dvdPlayer],
        ['asset.usbPlayer', asset.value.usbPlayer],
        ['asset.monitors', asset.value.monitors],
        ['asset.mp3', asset.value.mp3],
    ]),
    sortFalseLast([
        ['asset.kitchen', asset.value.kitchen],
        ['asset.coffeeMachine', asset.value.coffeeMachine],
        ['asset.refrigerator', asset.value.refrigerator],
        ['asset.toilet', asset.value.toilet],
    ]),
    sortFalseLast([
        ...insertIf<AssetSpecItem>(!!asset.value.glasses, () => ['asset.glasses.base', tt(`asset.glasses.${asset.value.glasses!}`)]),
        ['asset.openableWindows', asset.value.openableWindows],
        ['asset.curtains', asset.value.curtains],
    ]),
    sortFalseLast([
        ['asset.seatsMonitor', asset.value.seatsMonitor],
        ['asset.seatsRadio', asset.value.seatsRadio],
        ['asset.seatsUsbPorts', asset.value.seatsUsbPorts],
        ['asset.seatsSocket220', asset.value.seatsSocket220],
    ]),
    sortFalseLast([
        ...insertIf<AssetSpecItem>(!!asset.value.seatUpholstery, () => [
            'asset.seatUpholstery.base',
            tt(`asset.seatUpholstery.${asset.value.seatUpholstery!}`),
        ]),
        ['asset.recliningSeats', asset.value.recliningSeats],
        ['asset.extendableSeats', asset.value.extendableSeats],
    ]),
    sortFalseLast([
        ['asset.safetyBelts', asset.value.safetyBelts],
        ['asset.coffeeTables', asset.value.coffeeTables],
        ['asset.seatPocket', asset.value.seatPocket],
        ['asset.handRest', asset.value.handRest],
        ['asset.footRest', asset.value.footRest],
        ['asset.hatBox', asset.value.hatBox],
    ]),
]);

// METHODS
const getCountryTranslation = (_a: AuctionAssetBusEntity): string | null =>
    countries.find(_c => _c.countryCode === _a.registrationDocumentCountry)?.i18nNames?.[locale.value] ?? null;

const getConditionSpecs = (_a: AuctionAssetBusEntity): AssetSpecsGroup => [
    ...insertAssetPart(_a.enginePresent, _a.stateOfUseEngine, 'asset.engine'),
    ...insertAssetPart(_a.gearboxPresent, _a.stateOfUseGearbox, 'asset.transmission'),
    ...insertIf<AssetSpecItem>(!!_a.stateOfUseAirConditioner, () => [
        'asset.stateOfUseAirConditioner',
        tt(`asset.stateOfUse.${_a.stateOfUseAirConditioner!}`),
    ]),
    ...insertIf<AssetSpecItem>(!!_a.stateOfUseBreakingSystem, () => [
        'asset.stateOfUseBreakingSystem',
        tt(`asset.stateOfUse.${_a.stateOfUseBreakingSystem!}`),
    ]),
    ...insertIf<AssetSpecItem>(!!_a.stateOfUseDrivingOrgans, () => [
        'asset.stateOfUseDrivingOrgans',
        tt(`asset.stateOfUse.${_a.stateOfUseDrivingOrgans!}`),
    ]),
    ...insertIf<AssetSpecItem>(!!_a.stateOfUsePowerSystem, () => [
        'asset.stateOfUsePowerSystem',
        tt(`asset.stateOfUse.${_a.stateOfUsePowerSystem!}`),
    ]),
    ...insertIf<AssetSpecItem>(!!_a.stateOfUseTimingBelt, () => ['asset.stateOfUseTimingBelt', tt(`asset.stateOfUse.${_a.stateOfUseTimingBelt!}`)]),
    ...insertIf<AssetSpecItem>(!!_a.stateOfUseTransmissionDevices, () => [
        'asset.stateOfUseTransmissionDevices',
        tt(`asset.stateOfUse.${_a.stateOfUseTransmissionDevices!}`),
    ]),
    ...insertIf<AssetSpecItem>(!!_a.stateOfUseTurbine, () => ['asset.stateOfUseTurbine', tt(`asset.stateOfUse.${_a.stateOfUseTurbine!}`)]),
    ['asset.missingParts', lodashToString(_a.missingParts[locale.value]) || null],
];

const insertAssetPart = (present: boolean | null, stateOfUse: AssetStateOfUse | null, label: TranslationKey): AssetSpecItem[] => {
    if (present === false) return [[label, tt('asset.stateOfUse.missing')]];

    if (stateOfUse) return [[label, tt(`asset.stateOfUse.${stateOfUse}`)]];

    return [];
};

const removeTimezone = (date: string): string => date.replace(/Z$/, '');

const sortFalseLast = (items: AssetSpecItem[]): AssetSpecItem[] =>
    lodashSortBy(items, [(a): boolean | number => (typeof a[1] === 'boolean' ? !a[1] : 0)]);
</script>

<template>
    <section v-if="asset" class="flex flex-col gap-6">
        <div class="flex flex-col gap-6">
            <h3 class="text-3xl font-semibold md:text-4xl">
                <template v-if="isBundleMono">{{ tt('asset.bundleSpecs') }}</template>
                <template v-else>{{ tt('asset.vehicleSpecs') }}</template>
            </h3>
            <p v-if="!!asset.additionalNotes[locale]">{{ asset.additionalNotes[locale] }}</p>
        </div>

        <div>
            <AdpSpecsSection v-if="isBundleMono" :title="tt('asset.vehiclesInBundle')" :specs="bundleSpecs" default-open grouped-by-asset grid />

            <AdpSpecsSection :title="tt('asset.description')" :specs="descriptionSpecs" :default-open="!isBundleMono" grid />

            <AdpSpecsSection :title="tt('asset.conditionsAndMaintenance')" :specs="conditionsSpecs" :grouped-by-asset="isBundleMono" />

            <AdpSpecsSection :title="tt('asset.engine')" :specs="engineSpecs" grid />

            <AdpSpecsSection :title="tt('asset.transmission')" :specs="transmissionSpecs" grid />

            <AdpSpecsSection :title="tt('asset.axlesTires')" :specs="wheelsSpecs" grid />

            <AdpSpecsSection :title="tt('asset.accessories')" :specs="accessoriesSpecs" grid />
        </div>

        <AdpDocuments :asset="asset" />
    </section>
</template>
