<script setup lang="ts">
import type { PropType } from 'vue';

import type { AuctionItemEntityWithNegotiation } from '~/types/auction.type';

// PROPS
const { auction } = defineProps({
    auction: { type: Object as PropType<AuctionItemEntityWithNegotiation>, required: true },
});

// COMPOSABLES
const { tt, tn } = useTypedI18n();
const { negotiation, isBuyer, isUnderSellerReview, isClosed, isClosedAccepted } = useNegotiation(() => auction);

// METHODS
const getAmountColor = (from: 'buyer' | 'seller'): string => {
    if (isClosed.value && !isClosedAccepted.value) return 'text-black';

    const fromBuyer = from === 'buyer';

    if (isUnderSellerReview.value && fromBuyer) return 'text-red-600';

    return fromBuyer === isBuyer.value ? 'text-primary-500' : 'text-neutral-500';
};
</script>

<template>
    <div class="grid grid-cols-2 gap-4">
        <div class="lg:space-y-1">
            <p class="text-2xl font-semibold lg:text-3xl" :class="getAmountColor('seller')">
                {{ tn(negotiation.currentSellerProposal.amount, 'currency') }}
            </p>
            <p class="text-xs text-neutral-500 lg:text-sm">
                {{ tt(isBuyer ? 'negotiation.sellerRequest' : 'negotiation.yourRequest') }}
            </p>
        </div>
        <div class="lg:space-y-1">
            <p class="text-2xl font-semibold lg:text-3xl" :class="getAmountColor('buyer')">
                {{ tn(negotiation.currentBuyerProposal.amount, 'currency') }}
            </p>
            <p class="text-xs text-neutral-500 lg:text-sm">
                {{ tt(isBuyer ? 'negotiation.yourOffer' : 'negotiation.buyerOffer') }}
            </p>
        </div>
    </div>
</template>
