<script setup lang="ts">
import type { PropType } from 'vue';

import type { AuctionItemEntityWithNegotiation } from '~/types/auction.type';

// PROPS
const { auction, view } = defineProps({
    auction: { type: Object as PropType<AuctionItemEntityWithNegotiation>, required: true },
    view: { type: String as PropType<'desktop' | 'mobile'>, required: true },
    class: { type: [String, Object, Array], default: undefined },
});

// COMPOSABLES
const { tt, tn } = useTypedI18n();
const { isLgOrGreater } = useResponsive();
const { negotiation, isBuyer, isClosed, isClosedAccepted, isClosedExpired, isUnderSellerReview } = useNegotiation(() => auction);

// DATA
const panelRef = ref<HTMLElement>();

const isProposalModalOpen = ref<boolean>(false);
const isAcceptModalOpen = ref(false);
const isBuyerStopProposalsModalOpen = ref(false);
const isSellerDeclineModalOpen = ref(false);

const showPanel = computed(() => (view === 'desktop') === isLgOrGreater.value);
const enableBackToPanelButton = computed(() => view === 'mobile' && !isClosed.value);

const closedNotAcceptedNotice = computed(() => {
    if (!isClosed.value || isClosedAccepted.value) return;
    if (isClosedExpired.value) return tt('negotiation.expiredNotice');

    return tt(isBuyer.value ? 'negotiation.sellerDeclineOfferNotice' : 'negotiation.youDeclineOfferNotice');
});
const buttonSize = computed(() => (isLgOrGreater.value ? 'xl' : 'md'));
</script>

<template>
    <template v-if="showPanel">
        <div ref="panelRef" class="scroll-mt-[calc(var(--header-height)+1rem)] overflow-clip rounded-xl border border-neutral-300 bg-white">
            <template v-if="isClosed">
                <div class="space-y-3 p-4 lg:space-y-4 lg:p-6" :class="isClosedAccepted ? 'bg-green-50' : 'bg-neutral-50'">
                    <p
                        class="text-xs font-extrabold uppercase tracking-widest lg:text-sm"
                        :class="isClosedAccepted ? 'text-green-600' : 'text-black'"
                    >
                        {{ tt(isClosedAccepted ? 'auction.auctionPanel.wonAuction' : 'auction.auctionPanel.state.Expired') }}
                    </p>

                    <p v-if="negotiation.closeAmount" class="text-2xl font-semibold text-green-800 lg:text-5xl">
                        {{ tn(negotiation.closeAmount, 'currency') }}
                    </p>
                    <AdpNegotiationDualAmounts v-else :auction="auction" />

                    <AdpAuctionPanelNoticeFee v-if="isClosedAccepted" class="text-xs lg:text-base" :is-seller="!isBuyer" />
                </div>

                <div v-if="!isClosedAccepted" class="flex items-center gap-2 border-t border-neutral-300 p-4">
                    <UIcon class="shrink-0 text-neutral-500" name="i-heroicons-information-circle-20-solid" />
                    <p class="text-xs text-neutral-500 lg:text-sm">{{ closedNotAcceptedNotice }}</p>
                </div>
            </template>

            <template v-else>
                <div class="bg-primary-50 space-y-3 p-4 lg:space-y-4 lg:p-6">
                    <div class="flex justify-between">
                        <div class="space-y-1">
                            <p class="text-xs font-extrabold uppercase tracking-widest lg:text-sm">
                                {{ tt('auction.auctionPanel.state.Expired') }}
                            </p>
                            <p class="text-xs text-neutral-500 lg:text-sm">{{ tt('negotiation.numberOfBids', { count: auction.numberOfBids }) }}</p>
                        </div>
                        <p class="text-xs font-extrabold uppercase tracking-widest text-red-600 lg:text-sm">
                            {{ tt('negotiation.underNegotiation') }}
                        </p>
                    </div>

                    <AdpNegotiationDualAmounts :auction="auction" />

                    <AdpAuctionPanelNoticeFee class="text-xs lg:text-base" :is-seller="!isBuyer" />
                </div>

                <div class="space-y-3 border-t border-neutral-300 p-4 lg:space-y-4 lg:p-6">
                    <AdpNegotiationMainNotice :auction="auction" />

                    <template v-if="isBuyer">
                        <template v-if="!isUnderSellerReview">
                            <UButton
                                class="mt-4 text-black"
                                :size="buttonSize"
                                color="secondary"
                                :label="tt('negotiation.updateOffer')"
                                block
                                @click="isProposalModalOpen = true"
                            />
                            <UButton :size="buttonSize" :label="tt('negotiation.acceptRequest')" block @click="isAcceptModalOpen = true" />
                            <UButton
                                color="red"
                                :size="buttonSize"
                                variant="link"
                                class="px-0"
                                block
                                :label="tt('negotiation.buyerStopProposals')"
                                @click="isBuyerStopProposalsModalOpen = true"
                            />
                        </template>
                    </template>

                    <template v-else>
                        <UButton
                            v-if="!isUnderSellerReview"
                            class="mt-4 text-black"
                            :size="buttonSize"
                            color="secondary"
                            :label="tt('negotiation.updateRequest')"
                            block
                            @click="isProposalModalOpen = true"
                        />
                        <UButton :size="buttonSize" :label="tt('negotiation.acceptOffer')" block @click="isAcceptModalOpen = true" />
                        <UButton
                            color="red"
                            :size="buttonSize"
                            variant="link"
                            class="px-0"
                            block
                            :label="tt('negotiation.sellerDecline')"
                            @click="isSellerDeclineModalOpen = true"
                        />
                    </template>
                </div>
            </template>
        </div>

        <AdpNegotiationProposalModal v-model="isProposalModalOpen" :auction="auction" />
        <AdpNegotiationAcceptModal v-model="isAcceptModalOpen" :auction="auction" />
        <AdpNegotiationBuyerStopProposalsModal v-model="isBuyerStopProposalsModalOpen" :auction="auction" />
        <AdpNegotiationSellerDeclineModal v-model="isSellerDeclineModalOpen" :auction="auction" />

        <AdpNegotiationBackToPanelButton v-if="panelRef && enableBackToPanelButton" :panel-ref="panelRef" />
    </template>
</template>
