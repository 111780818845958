import type { AuctionItemEntity, NegotiationPlatformEntity } from '~/apiClient';

export interface AuctionItemEntityWithCurrentPrice extends Omit<AuctionItemEntity, 'currentPrice'> {
    currentPrice: number;
}

export function isAuctionItemEntityWithCurrentPrince(entity: AuctionItemEntity): entity is AuctionItemEntityWithCurrentPrice {
    return typeof entity.currentPrice === 'number';
}

export interface AuctionItemEntityWithNegotiation extends Omit<AuctionItemEntity, 'negotiation'> {
    negotiation: NegotiationPlatformEntity;
}

export const isAuctionItemEntityWithNegotiation = (auction: AuctionItemEntity): auction is AuctionItemEntityWithNegotiation =>
    auction.negotiation !== null;
