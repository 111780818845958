import type { Ref } from 'vue';

import {
    BackendErrorCode,
    type BackendErrorEntity,
    type ErrorType,
    NegotiationOutcome,
    type NegotiationPlatformEntity,
    NegotiationState,
    useApiAcceptNegotiation,
    useApiBuyerStopNegotiationProposals,
    useApiMakeNegotiationProposal,
    useApiSellerDeclineNegotiation,
} from '~/apiClient';
import type { AuctionItemEntityWithNegotiation } from '~/types/auction.type';
import { NotificationTimeout } from '~/types/notifications.type';

type UseNegotiationReturn = {
    negotiation: ComputedRef<NegotiationPlatformEntity>;
    isBuyer: ComputedRef<boolean>;
    isClosed: ComputedRef<boolean>;
    isClosedAccepted: ComputedRef<boolean>;
    isClosedExpired: ComputedRef<boolean>;
    isUnderSellerReview: ComputedRef<boolean>;
    counterPartProposal: ComputedRef<number>;

    buyerStopProposals: () => Promise<NegotiationPlatformEntity>;
    buyerStopProposalsIsPending: Ref<boolean>;
    makeProposal: (amount: number) => Promise<NegotiationPlatformEntity>;
    makeProposalIsPending: Ref<boolean>;
    accept: () => Promise<NegotiationPlatformEntity>;
    acceptIsPending: Ref<boolean>;
    sellerDecline: () => Promise<NegotiationPlatformEntity>;
    sellerDeclineIsPending: Ref<boolean>;
};

export default function useNegotiation(auction: MaybeRefOrGetter<AuctionItemEntityWithNegotiation>): UseNegotiationReturn {
    // COMPOSABLES
    const { tt, tn } = useTypedI18n();
    const { notifySuccess, notifyInfo, notifyError } = useNotification();
    const { logError } = useLogs();
    const { data: authData } = useAuthUtils();
    const { mutateAsync: buyerStopProposalsMutateAsync, isPending: buyerStopProposalsIsPending } = useApiBuyerStopNegotiationProposals();
    const { mutateAsync: makeProposalMutateAsync, isPending: makeProposalIsPending } = useApiMakeNegotiationProposal();
    const { mutateAsync: acceptMutateAsync, isPending: acceptIsPending } = useApiAcceptNegotiation();
    const { mutateAsync: sellerDeclineMutateAsync, isPending: sellerDeclineIsPending } = useApiSellerDeclineNegotiation();

    // DATA
    const negotiation = computed(() => toValue(auction).negotiation);
    const isBuyer = computed(() => negotiation.value.buyerCompanyId === authData.value?.companyId);
    const isClosed = computed(() => negotiation.value.state === NegotiationState.Closed);
    const isClosedAccepted = computed(
        () =>
            isClosed.value &&
            !!negotiation.value.outcome &&
            [NegotiationOutcome.AcceptedByBuyer, NegotiationOutcome.AcceptedBySeller].includes(negotiation.value.outcome)
    );
    const isClosedExpired = computed(() => isClosed.value && negotiation.value.outcome === NegotiationOutcome.Expired);
    const isUnderSellerReview = computed(() => negotiation.value.state === NegotiationState.UnderSellerReview);
    const counterPartProposal = computed(() =>
        isBuyer.value ? negotiation.value.currentSellerProposal.amount : negotiation.value.currentBuyerProposal.amount
    );

    // METHODS
    const notifyGenericApiError = (apiError: ErrorType<BackendErrorEntity>): never => {
        if (
            isBackendError(apiError.data) &&
            [BackendErrorCode.AuthTokenExpired, BackendErrorCode.AuthTokenNotValid].includes(apiError.data.error.code)
        ) {
            // Errors already handled elsewhere
        } else {
            notifyError({
                title: tt('negotiation.notifications.apiError.title'),
                description: tt('negotiation.notifications.apiError.description'),
                timeout: NotificationTimeout.NoTimeout,
            });
        }

        throw apiError;
    };

    const makeLogErrorHandler =
        (action: string) =>
        (error: ErrorType<BackendErrorEntity>, variables: Object): void => {
            logError(`Error in Negotiation ${action}`, { apiError: error.data, apiData: variables });
        };

    const buyerStopProposals: UseNegotiationReturn['buyerStopProposals'] = () =>
        buyerStopProposalsMutateAsync({ negotiationId: negotiation.value.id }, { onError: makeLogErrorHandler('buyerStopProposals') })
            .then(updatedNegotiation => {
                notifyInfo({
                    title: tt('negotiation.notifications.stopProposalsByBuyer.title'),
                    description: tt('negotiation.notifications.stopProposalsByBuyer.description', {
                        amount: tn(updatedNegotiation.currentBuyerProposal.amount, 'currency'),
                    }),
                });

                return updatedNegotiation;
            })
            .catch(notifyGenericApiError);

    const makeProposal: UseNegotiationReturn['makeProposal'] = amount =>
        makeProposalMutateAsync({ negotiationId: negotiation.value.id, data: { amount } }, { onError: makeLogErrorHandler('makeProposal') })
            .then(updatedNegotiation => {
                if (updatedNegotiation.outcome && updatedNegotiation.closeAmount === amount) {
                    notifySuccess({
                        title: tt(isBuyer.value ? 'negotiation.notifications.acceptByBuyer.title' : 'negotiation.notifications.acceptBySeller.title'),
                        description: tt(
                            isBuyer.value
                                ? 'negotiation.notifications.acceptByBuyer.description'
                                : 'negotiation.notifications.acceptBySeller.description',
                            { amount: tn(amount, 'currency') }
                        ),
                    });
                } else {
                    notifyInfo({
                        title: tt(
                            isBuyer.value ? 'negotiation.notifications.proposalByBuyer.title' : 'negotiation.notifications.proposalBySeller.title'
                        ),
                        description: tt(
                            isBuyer.value
                                ? 'negotiation.notifications.proposalByBuyer.description'
                                : 'negotiation.notifications.proposalBySeller.description',
                            { amount: tn(amount, 'currency') }
                        ),
                    });
                }

                return updatedNegotiation;
            })
            .catch(notifyGenericApiError);

    const accept: UseNegotiationReturn['accept'] = () =>
        acceptMutateAsync({ negotiationId: negotiation.value.id }, { onError: makeLogErrorHandler('accept') })
            .then(updatedNegotiation => {
                notifySuccess({
                    title: tt(isBuyer.value ? 'negotiation.notifications.acceptByBuyer.title' : 'negotiation.notifications.acceptBySeller.title'),
                    description: tt(
                        isBuyer.value
                            ? 'negotiation.notifications.acceptByBuyer.description'
                            : 'negotiation.notifications.acceptBySeller.description',
                        { amount: tn(updatedNegotiation.closeAmount ?? counterPartProposal.value, 'currency') }
                    ),
                });

                return updatedNegotiation;
            })
            .catch(notifyGenericApiError);

    const sellerDecline: UseNegotiationReturn['sellerDecline'] = () =>
        sellerDeclineMutateAsync({ negotiationId: negotiation.value.id }, { onError: makeLogErrorHandler('sellerDecline') })
            .then(updatedNegotiation => {
                notifyInfo({
                    title: tt('negotiation.notifications.declinedBySeller.title'),
                    description: tt('negotiation.notifications.declinedBySeller.description', {
                        amount: tn(negotiation.value.currentBuyerProposal.amount, 'currency'),
                    }),
                });

                return updatedNegotiation;
            })
            .catch(notifyGenericApiError);

    return {
        negotiation,
        isBuyer,
        isClosed,
        isClosedAccepted,
        isClosedExpired,
        isUnderSellerReview,
        counterPartProposal,

        buyerStopProposals,
        buyerStopProposalsIsPending,
        makeProposal,
        makeProposalIsPending,
        accept,
        acceptIsPending,
        sellerDecline,
        sellerDeclineIsPending,
    };
}
