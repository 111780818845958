<script setup lang="ts">
import { useQueryClient } from '@tanstack/vue-query';

import { type BackendErrorEntity, type ErrorType, getApiAuctionDetailQueryKey, useApiApproveAuction } from '~/apiClient';
import type { ToolbarAlertAction } from '~/components/Base/BaseToolbarAlert.vue';
import { AUCTION_DETAIL_QUERY_KEY_PREFIX } from '~/constants/queryKeyPrefix';

// COMPOSABLES
const { id } = useAuctionDetailParams();
const { isLoggedIn } = useAuthUtils();
const { tt } = useTypedI18n();
const { logError } = useLogs();
const { notifySuccess, notifyBackendError, notifyGenericError } = useNotification();
const queryClient = useQueryClient();
const { openLoginModal } = useModals();

// DATA
const { mutateAsync: mutateApproveDraft, isPending: isApprovingDraft } = useApiApproveAuction();
const actions = computed<ToolbarAlertAction[]>(() =>
    isLoggedIn.value
        ? [
              {
                  label: tt('auction.draftConfirmation.action'),
                  color: 'white',
                  size: 'lg',
                  class: 'text-primary ring-0 justify-center',
                  icon: 'mdi-check-underline-circle',
                  click: (): void => {
                      id.value &&
                          mutateApproveDraft({ auctionId: id.value })
                              .then(data => {
                                  notifySuccess({
                                      title: tt('auction.draftConfirmation.success.title'),
                                      description: tt('auction.draftConfirmation.success.description'),
                                  });
                                  queryClient.setQueryData([AUCTION_DETAIL_QUERY_KEY_PREFIX, ...getApiAuctionDetailQueryKey(id)], data);
                              })
                              .catch((apiError: ErrorType<BackendErrorEntity>) => {
                                  logError('Error confirm draft', { error: apiError });

                                  if (!apiError.data?.error) {
                                      notifyGenericError();

                                      return;
                                  }

                                  const { error } = apiError.data;
                                  notifyBackendError({ error });
                              });
                  },
                  loading: isApprovingDraft.value,
              },
          ]
        : [
              {
                  label: tt('auction.draftConfirmation.loginAction'),
                  color: 'white',
                  size: 'lg',
                  class: 'text-black ring-0 justify-center',
                  icon: 'mdi-check-underline-circle',
                  click: openLoginModal,
              },
          ]
);
</script>

<template>
    <BaseToolbarAlert
        :title="tt('auction.draftConfirmation.title')"
        :description="tt('auction.draftConfirmation.description')"
        :actions="actions"
        class="text-white"
        :class="{ 'bg-primary': isLoggedIn, 'bg-neutral-500': !isLoggedIn }"
        icon="mdi-file-eye"
    />
</template>

<style scoped></style>
