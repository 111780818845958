<script setup lang="ts">
defineProps({
    isSeller: { type: Boolean, default: false },
});

const { tt } = useTypedI18n();
const localePath = useLocalePath();
</script>

<template>
    <p class="text-neutral-500">
        <span>{{ isSeller ? '-' : '+' }}&nbsp;</span>
        <i18n-t scope="global" tag="span" keypath="auction.auctionPanel.feeNotice">
            <NuxtLinkLocale
                :to="localePath(isSeller ? 'pricing-seller' : 'pricing-buyer')"
                class="whitespace-nowrap text-black underline"
                target="_blank"
            >
                {{ tt('auction.auctionPanel.feeNoticeLink') }}
            </NuxtLinkLocale>
        </i18n-t>
    </p>
</template>
