<script setup lang="ts">
import type { PropType } from 'vue';

import type { AuctionItemEntityWithNegotiation } from '~/types/auction.type';

// PROPS
const { auction } = defineProps({
    auction: { type: Object as PropType<AuctionItemEntityWithNegotiation>, required: true },
});

// COMPOSABLES
const { tt } = useTypedI18n();
const localePath = useLocalePath();
const { negotiation, isBuyer, isUnderSellerReview } = useNegotiation(() => auction);

// DATA
const translationKey = computed<TranslationKey>(() => {
    if (isUnderSellerReview.value)
        return isBuyer.value ? 'negotiation.underSellerReviewNoticeForBuyer' : 'negotiation.underSellerReviewNoticeForSeller';

    return isBuyer.value ? 'negotiation.openNoticeForBuyer' : 'negotiation.openNoticeForSeller';
});

const finalOfferLabel = computed(() =>
    tt(isBuyer.value ? 'negotiation.underSellerReviewNoticeFinalOfferForBuyer' : 'negotiation.underSellerReviewNoticeFinalOfferForSeller')
);
</script>

<template>
    <div class="flex items-center gap-2">
        <UIcon class="shrink-0 text-red-600" name="i-heroicons-information-circle-20-solid" />
        <i18n-t scope="global" tag="p" class="text-xs text-neutral-500 lg:text-base" :keypath="translationKey">
            <template v-if="isUnderSellerReview" #finalOffer>
                <span class="text-black">{{ finalOfferLabel }}</span>
            </template>

            <template #countdown>
                <AdpNegotiationCountdown :expires-at="negotiation.expiresAt" class="text-primary-500" />
            </template>

            <template #moreInfoLink>
                <NuxtLink :to="localePath({ name: 'faq', hash: '#negotiation' })" class="whitespace-nowrap text-black underline" target="_blank">
                    {{ tt('negotiation.moreInfoLink') }}
                </NuxtLink>
            </template>
        </i18n-t>
    </div>
</template>
