export class AuctionSteppedPrice {
    private readonly effectiveThreshold: number;

    constructor(
        private readonly minBidStepAmount: number,
        minBidStepAmountThreshold: number,
        private readonly maxBidStepAmount: number
    ) {
        this.effectiveThreshold = Math.ceil(minBidStepAmountThreshold / minBidStepAmount) * minBidStepAmount;
    }

    validateAmount(amount: number): boolean {
        const [delta, step] =
            amount < this.effectiveThreshold ? [amount, this.minBidStepAmount] : [amount - this.effectiveThreshold, this.maxBidStepAmount];

        return delta % step === 0;
    }

    getStepRoundedAmount(approxAmount: number): number {
        return this.getStepRoundedValue(approxAmount, Math.floor);
    }

    getStepRoundedReservePrice(reservePrice: number): number {
        return this.getStepRoundedValue(reservePrice, Math.ceil);
    }

    getDeltaStepsAmount(baseAmount: number, deltaSteps: number): number {
        if (!this.validateAmount(baseAmount)) {
            throw new Error('Error in AuctionSteppedPrice.getDeltaStepsAmount, base is not a valid amount');
        }

        return this.computeDeltaStepsAmount(baseAmount, deltaSteps);
    }

    private getStepRoundedValue(approxValue: number, roundFunc: Math['floor'] | Math['ceil']): number {
        const [delta, step, base] =
            approxValue < this.effectiveThreshold
                ? [approxValue, this.minBidStepAmount, 0]
                : [approxValue - this.effectiveThreshold, this.maxBidStepAmount, this.effectiveThreshold];

        return roundFunc(delta / step) * step + base;
    }

    private computeDeltaStepsAmount(baseAmount: number, deltaSteps: number): number {
        const singleStep = deltaSteps / Math.abs(deltaSteps);

        return deltaSteps === 0
            ? baseAmount
            : this.computeDeltaStepsAmount(
                  baseAmount +
                      ((deltaSteps > 0 ? baseAmount < this.effectiveThreshold : baseAmount <= this.effectiveThreshold)
                          ? this.minBidStepAmount
                          : this.maxBidStepAmount) *
                          singleStep,
                  deltaSteps - singleStep
              );
    }
}
