<script setup lang="ts">
const { expiresAt } = defineProps({
    expiresAt: { type: String, required: true },
});

const { totalSeconds, countdownLabel, timeDatetimeAttribute } = useCountdown(toRef(() => expiresAt));
</script>

<template>
    <time v-if="totalSeconds > 0" :datetime="timeDatetimeAttribute">{{ countdownLabel }}</time>
</template>
