import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationReturnType,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ApiGetNegotiationsParams,
  BackendErrorEntity,
  MakeNegotiationProposalBodyDto,
  NegotiationEntity,
  NegotiationPlatformEntity,
  StartNextNegotiationBodyDto
} from '.././types'
import { fetchApiInstance } from '../../fetchApiInstance';
import type { ErrorType, BodyType } from '../../fetchApiInstance';

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const apiStartNextNegotiation = (
    startNextNegotiationBodyDto: MaybeRef<StartNextNegotiationBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      startNextNegotiationBodyDto = unref(startNextNegotiationBodyDto);
      
      return fetchApiInstance<NegotiationEntity>(
      {url: `/negotiations`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: startNextNegotiationBodyDto
    },
      options);
    }
  


export const getApiStartNextNegotiationMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiStartNextNegotiation>>, TError,{data: BodyType<StartNextNegotiationBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiStartNextNegotiation>>, TError,{data: BodyType<StartNextNegotiationBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiStartNextNegotiation>>, {data: BodyType<StartNextNegotiationBodyDto>}> = (props) => {
          const {data} = props ?? {};

          return  apiStartNextNegotiation(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiStartNextNegotiationMutationResult = NonNullable<Awaited<ReturnType<typeof apiStartNextNegotiation>>>
    export type ApiStartNextNegotiationMutationBody = BodyType<StartNextNegotiationBodyDto>
    export type ApiStartNextNegotiationMutationError = ErrorType<BackendErrorEntity>

    export const useApiStartNextNegotiation = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiStartNextNegotiation>>, TError,{data: BodyType<StartNextNegotiationBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiStartNextNegotiation>>,
        TError,
        {data: BodyType<StartNextNegotiationBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiStartNextNegotiationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiGetNegotiations = (
    params: MaybeRef<ApiGetNegotiationsParams>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      params = unref(params);
      
      return fetchApiInstance<NegotiationEntity[]>(
      {url: `/negotiations`, method: 'GET',
        params: unref(params)
    },
      options);
    }
  

export const getApiGetNegotiationsQueryKey = (params: MaybeRef<ApiGetNegotiationsParams>,) => {
    return ['negotiations', ...(params ? [params]: [])] as const;
    }

    
export const getApiGetNegotiationsQueryOptions = <TData = Awaited<ReturnType<typeof apiGetNegotiations>>, TError = ErrorType<BackendErrorEntity>>(params: MaybeRef<ApiGetNegotiationsParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetNegotiations>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetNegotiationsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetNegotiations>>> = () => apiGetNegotiations(params, requestOptions);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetNegotiations>>, TError, TData> 
}

export type ApiGetNegotiationsQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetNegotiations>>>
export type ApiGetNegotiationsQueryError = ErrorType<BackendErrorEntity>



export function useApiGetNegotiations<TData = Awaited<ReturnType<typeof apiGetNegotiations>>, TError = ErrorType<BackendErrorEntity>>(
 params: MaybeRef<ApiGetNegotiationsParams>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetNegotiations>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetNegotiationsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



export const apiMakeNegotiationProposal = (
    negotiationId: MaybeRef<string | undefined | null>,
    makeNegotiationProposalBodyDto: MaybeRef<MakeNegotiationProposalBodyDto>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      negotiationId = unref(negotiationId);
makeNegotiationProposalBodyDto = unref(makeNegotiationProposalBodyDto);
      
      return fetchApiInstance<NegotiationPlatformEntity>(
      {url: `/negotiations/${negotiationId}/negotiationProposal`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: makeNegotiationProposalBodyDto
    },
      options);
    }
  


export const getApiMakeNegotiationProposalMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiMakeNegotiationProposal>>, TError,{negotiationId: string;data: BodyType<MakeNegotiationProposalBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiMakeNegotiationProposal>>, TError,{negotiationId: string;data: BodyType<MakeNegotiationProposalBodyDto>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiMakeNegotiationProposal>>, {negotiationId: string;data: BodyType<MakeNegotiationProposalBodyDto>}> = (props) => {
          const {negotiationId,data} = props ?? {};

          return  apiMakeNegotiationProposal(negotiationId,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiMakeNegotiationProposalMutationResult = NonNullable<Awaited<ReturnType<typeof apiMakeNegotiationProposal>>>
    export type ApiMakeNegotiationProposalMutationBody = BodyType<MakeNegotiationProposalBodyDto>
    export type ApiMakeNegotiationProposalMutationError = ErrorType<BackendErrorEntity>

    export const useApiMakeNegotiationProposal = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiMakeNegotiationProposal>>, TError,{negotiationId: string;data: BodyType<MakeNegotiationProposalBodyDto>}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiMakeNegotiationProposal>>,
        TError,
        {negotiationId: string;data: BodyType<MakeNegotiationProposalBodyDto>},
        TContext
      > => {

      const mutationOptions = getApiMakeNegotiationProposalMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiAcceptNegotiation = (
    negotiationId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      negotiationId = unref(negotiationId);
      
      return fetchApiInstance<NegotiationPlatformEntity>(
      {url: `/negotiations/${negotiationId}/accept`, method: 'POST'
    },
      options);
    }
  


export const getApiAcceptNegotiationMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiAcceptNegotiation>>, TError,{negotiationId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiAcceptNegotiation>>, TError,{negotiationId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiAcceptNegotiation>>, {negotiationId: string}> = (props) => {
          const {negotiationId} = props ?? {};

          return  apiAcceptNegotiation(negotiationId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiAcceptNegotiationMutationResult = NonNullable<Awaited<ReturnType<typeof apiAcceptNegotiation>>>
    
    export type ApiAcceptNegotiationMutationError = ErrorType<BackendErrorEntity>

    export const useApiAcceptNegotiation = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiAcceptNegotiation>>, TError,{negotiationId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiAcceptNegotiation>>,
        TError,
        {negotiationId: string},
        TContext
      > => {

      const mutationOptions = getApiAcceptNegotiationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiSellerDeclineNegotiation = (
    negotiationId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      negotiationId = unref(negotiationId);
      
      return fetchApiInstance<NegotiationPlatformEntity>(
      {url: `/negotiations/${negotiationId}/seller/decline`, method: 'POST'
    },
      options);
    }
  


export const getApiSellerDeclineNegotiationMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiSellerDeclineNegotiation>>, TError,{negotiationId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiSellerDeclineNegotiation>>, TError,{negotiationId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiSellerDeclineNegotiation>>, {negotiationId: string}> = (props) => {
          const {negotiationId} = props ?? {};

          return  apiSellerDeclineNegotiation(negotiationId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiSellerDeclineNegotiationMutationResult = NonNullable<Awaited<ReturnType<typeof apiSellerDeclineNegotiation>>>
    
    export type ApiSellerDeclineNegotiationMutationError = ErrorType<BackendErrorEntity>

    export const useApiSellerDeclineNegotiation = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiSellerDeclineNegotiation>>, TError,{negotiationId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiSellerDeclineNegotiation>>,
        TError,
        {negotiationId: string},
        TContext
      > => {

      const mutationOptions = getApiSellerDeclineNegotiationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiBuyerStopNegotiationProposals = (
    negotiationId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      negotiationId = unref(negotiationId);
      
      return fetchApiInstance<NegotiationPlatformEntity>(
      {url: `/negotiations/${negotiationId}/buyer/stopProposals`, method: 'POST'
    },
      options);
    }
  


export const getApiBuyerStopNegotiationProposalsMutationOptions = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiBuyerStopNegotiationProposals>>, TError,{negotiationId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationOptions<Awaited<ReturnType<typeof apiBuyerStopNegotiationProposals>>, TError,{negotiationId: string}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof apiBuyerStopNegotiationProposals>>, {negotiationId: string}> = (props) => {
          const {negotiationId} = props ?? {};

          return  apiBuyerStopNegotiationProposals(negotiationId,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ApiBuyerStopNegotiationProposalsMutationResult = NonNullable<Awaited<ReturnType<typeof apiBuyerStopNegotiationProposals>>>
    
    export type ApiBuyerStopNegotiationProposalsMutationError = ErrorType<BackendErrorEntity>

    export const useApiBuyerStopNegotiationProposals = <TError = ErrorType<BackendErrorEntity>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof apiBuyerStopNegotiationProposals>>, TError,{negotiationId: string}, TContext>, request?: SecondParameter<typeof fetchApiInstance>}
): UseMutationReturnType<
        Awaited<ReturnType<typeof apiBuyerStopNegotiationProposals>>,
        TError,
        {negotiationId: string},
        TContext
      > => {

      const mutationOptions = getApiBuyerStopNegotiationProposalsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const apiGetNegotiationDetail = (
    negotiationId: MaybeRef<string | undefined | null>,
 options?: SecondParameter<typeof fetchApiInstance>,) => {
      negotiationId = unref(negotiationId);
      
      return fetchApiInstance<NegotiationPlatformEntity>(
      {url: `/negotiations/${negotiationId}`, method: 'GET'
    },
      options);
    }
  

export const getApiGetNegotiationDetailQueryKey = (negotiationId: MaybeRef<string | undefined | null>,) => {
    return ['negotiations',negotiationId] as const;
    }

    
export const getApiGetNegotiationDetailQueryOptions = <TData = Awaited<ReturnType<typeof apiGetNegotiationDetail>>, TError = ErrorType<BackendErrorEntity>>(negotiationId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetNegotiationDetail>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getApiGetNegotiationDetailQueryKey(negotiationId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof apiGetNegotiationDetail>>> = () => apiGetNegotiationDetail(negotiationId, requestOptions);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(negotiationId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof apiGetNegotiationDetail>>, TError, TData> 
}

export type ApiGetNegotiationDetailQueryResult = NonNullable<Awaited<ReturnType<typeof apiGetNegotiationDetail>>>
export type ApiGetNegotiationDetailQueryError = ErrorType<BackendErrorEntity>



export function useApiGetNegotiationDetail<TData = Awaited<ReturnType<typeof apiGetNegotiationDetail>>, TError = ErrorType<BackendErrorEntity>>(
 negotiationId: MaybeRef<string | undefined | null>, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof apiGetNegotiationDetail>>, TError, TData>, request?: SecondParameter<typeof fetchApiInstance>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getApiGetNegotiationDetailQueryOptions(negotiationId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = unref(queryOptions).queryKey as QueryKey;

  return query;
}



