<script setup lang="ts">
import type { PropType } from 'vue';

import { type AuctionItemEntity, AuctionState } from '~/apiClient';

// PROPS
const { auction } = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity>, required: true },
});

// COMPOSABLES
const { tt } = useTypedI18n();
const localePath = useLocalePath();
const { isTablet } = useResponsive();
const { statusSectionColor, hasRestrictedNotice, hasInspectionNotice, hasReserveNotice } = useAuctionStatus(toRef(() => auction));

// COMPUTED
const hasPlaceBid = computed(() => auction.state === AuctionState.Running);
const hasBidSection = computed(() => hasRestrictedNotice.value || hasInspectionNotice.value || hasReserveNotice.value || hasPlaceBid.value);

const statusSectionClasses = computed(() => [
    ...insertIf(hasBidSection.value, isTablet.value ? 'rounded-l-2xl' : 'rounded-t-2xl'),
    ...insertIf(!hasBidSection.value, 'col-span-2', 'rounded-2xl'),
    statusSectionColor.value,
]);
</script>

<template>
    <div class="rounded-2xl drop-shadow-xl md:grid md:grid-cols-2 lg:block lg:drop-shadow-none">
        <div class="border border-solid border-gray-200 p-4 lg:p-6" :class="statusSectionClasses">
            <AdpAuctionPanelStatusSection :auction="auction" />
        </div>
        <div
            v-if="hasBidSection"
            class="flex flex-col justify-center border border-solid border-gray-200 bg-white p-4 lg:p-6"
            :class="isTablet ? 'rounded-r-2xl border-l-0' : 'rounded-b-2xl border-t-0'"
        >
            <div class="flex flex-col gap-3">
                <AdpAuctionPanelNoticeRestricted v-if="hasRestrictedNotice" :auction="auction" />
                <template v-else>
                    <AdpAuctionPanelNoticeBase
                        v-if="hasInspectionNotice"
                        :message="tt('auction.auctionPanel.notice.inspectionExpired')"
                        icon-name="i-mdi-alert"
                        icon-class="text-yellow-500"
                    />
                    <AdpAuctionPanelNoticeBase
                        v-if="hasReserveNotice"
                        :to="localePath({ name: 'faq', hash: '#reserve-price' })"
                        icon-name="i-mdi-shield-alert"
                        icon-class="text-red-600"
                    >
                        <i18n-t scope="global" tag="span" class="text-neutral-500" keypath="auction.auctionPanel.notice.reservePriceNotReached.base">
                            <span class="text-red-600">{{ tt('auction.auctionPanel.notice.reservePriceNotReached.notReached') }}</span>
                        </i18n-t>
                    </AdpAuctionPanelNoticeBase>
                    <AdpAuctionPanelPlaceBid v-if="hasPlaceBid" :auction="auction" />
                </template>
            </div>
        </div>
    </div>
</template>
