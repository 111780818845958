<script setup lang="ts">
import type { PropType } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import { type AuctionItemEntity, BackendErrorCode } from '~/apiClient';

// PROPS
const { auction } = defineProps({
    auction: { type: Object as PropType<AuctionItemEntity>, required: true },
});

// COMPOSABLES
const { tt } = useTypedI18n();
const localePath = useLocalePath();

// COMPUTED
const cantBidReason = computed(() => auction.userMeta?.cantBidReason);
const notice = computed<{ message: TypedTranslateReturn; to?: RouteLocationRaw }>(() => {
    if (cantBidReason.value === BackendErrorCode.CantBidUnapprovedCompany)
        return { message: tt(`auction.auctionPanel.notice.cantParticipate.${BackendErrorCode.CantBidUnapprovedCompany}`) };

    if (cantBidReason.value === BackendErrorCode.CantBidScrapAuctionNotScrapyardCompany)
        return {
            message: tt(`auction.auctionPanel.notice.cantParticipate.${BackendErrorCode.CantBidScrapAuctionNotScrapyardCompany}`),
            to: localePath({ name: 'faq', hash: '#scrapping' }),
        };

    if (cantBidReason.value === BackendErrorCode.CantBidUserCompanyEqSellerCompany)
        return { message: tt(`auction.auctionPanel.notice.cantParticipate.${BackendErrorCode.CantBidUserCompanyEqSellerCompany}`) };

    if (cantBidReason.value === BackendErrorCode.CantBidCompanyUnderReview)
        return { message: tt(`auction.auctionPanel.notice.cantParticipate.${BackendErrorCode.CantBidCompanyUnderReview}`) };

    if (cantBidReason.value === BackendErrorCode.CantBidRegisteredCompany)
        return { message: tt(`auction.auctionPanel.notice.cantParticipate.${BackendErrorCode.CantBidRegisteredCompany}`) };

    if (cantBidReason.value === BackendErrorCode.CantBidUserWithoutCompany)
        return { message: tt(`auction.auctionPanel.notice.cantParticipate.${BackendErrorCode.CantBidUserWithoutCompany}`) };

    if (cantBidReason.value === BackendErrorCode.CantBidCompanyNotYetApprovedForScrap)
        return { message: tt(`auction.auctionPanel.notice.cantParticipate.${BackendErrorCode.CantBidCompanyNotYetApprovedForScrap}`) };

    if (cantBidReason.value === BackendErrorCode.CantBidCompanyNotApprovedForScrap)
        return { message: tt(`auction.auctionPanel.notice.cantParticipate.${BackendErrorCode.CantBidCompanyNotApprovedForScrap}`) };

    // BackendErrorCode.CantBidUnhandledScenario || BackendErrorCode.CantBidBannedCompany
    return { message: tt(`auction.auctionPanel.notice.cantParticipate.generic`) };
});
</script>

<template>
    <AdpAuctionPanelNoticeBase :message="notice.message" :to="notice.to" icon-class="text-primary" icon-name="i-mdi-alert-circle" />
</template>
