<script setup lang="ts">
import { AuctionSteppedPrice } from '@lib/shared';
import type { PropType } from 'vue';

import { type NegotiationPlatformEntity, NegotiationState } from '~/apiClient';
import type { AuctionItemEntityWithNegotiation } from '~/types/auction.type';

// PROPS
const { auction } = defineProps({
    auction: { type: Object as PropType<AuctionItemEntityWithNegotiation>, required: true },
});

const isModalOpen = defineModel<boolean>({ required: true });

// COMPOSABLES
const { tt, tn } = useTypedI18n();
const { isMobile } = useResponsive();
const { negotiation, isBuyer, makeProposal, makeProposalIsPending, buyerStopProposals, buyerStopProposalsIsPending } = useNegotiation(() => auction);

const getAmount = (base: number, deltaSteps: number): number => {
    const auctionSteppedPrice = new AuctionSteppedPrice(auction.minBidStepAmount, auction.minBidStepAmountThreshold, auction.maxBidStepAmount);

    return auctionSteppedPrice.getDeltaStepsAmount(auctionSteppedPrice.getStepRoundedReservePrice(base), deltaSteps);
};

// DATA
const buyerStopProposalsEnabled = ref(false);
const modalUi = { container: 'items-center', width: 'sm:max-w-md' };
const currentProposal = computed(() =>
    isBuyer.value ? negotiation.value.currentBuyerProposal.amount : negotiation.value.currentSellerProposal.amount
);
const selectedProposal = ref(currentProposal.value);
const minProposal = computed(() =>
    isBuyer.value ? getAmount(negotiation.value.currentBuyerProposal.amount, 1) : negotiation.value.currentBuyerProposal.amount
);
const maxProposal = computed(() =>
    isBuyer.value ? negotiation.value.currentSellerProposal.amount : getAmount(negotiation.value.currentSellerProposal.amount, -1)
);
const confirmButtonDisabled = computed(
    () =>
        selectedProposal.value > maxProposal.value ||
        (buyerStopProposalsEnabled.value && selectedProposal.value < currentProposal.value) ||
        (!buyerStopProposalsEnabled.value && selectedProposal.value < minProposal.value)
);
const resetProposalDisabled = computed(
    () => selectedProposal.value === (isBuyer.value ? negotiation.value.currentBuyerProposal.amount : negotiation.value.currentSellerProposal.amount)
);

const multiStepButtonsData = computed(() => {
    const absAllSteps = [2, 5, 10];
    const allSteps = isBuyer.value ? absAllSteps : absAllSteps.map(val => val * -1);

    return allSteps.map(steps => {
        const amount = getAmount(selectedProposal.value, steps);
        const delta = amount - selectedProposal.value;
        const labelSignPrefix = delta > 0 ? '+' : '';

        return {
            key: `steps_${steps}`,
            label: `${labelSignPrefix}${tn(delta, 'currency')}`,
            click: (): void => {
                selectedProposal.value = amount;
            },
            hidden: amount > maxProposal.value || amount < minProposal.value,
        };
    });
});

// METHODS
const closeModal = (): void => {
    isModalOpen.value = false;
};

const resetSelectedProposal = (): void => {
    selectedProposal.value = isBuyer.value ? negotiation.value.currentBuyerProposal.amount : negotiation.value.currentSellerProposal.amount;
};

const resetModal = (): void => {
    resetSelectedProposal();
    buyerStopProposalsEnabled.value = false;
};

const onUpdateProposal = (steps: number): void => {
    selectedProposal.value = Math.min(getAmount(selectedProposal.value, steps), negotiation.value.currentSellerProposal.amount);
};

const onMakeProposal = async (): Promise<void> => {
    try {
        let updatedNegotiation: NegotiationPlatformEntity | undefined;

        if (selectedProposal.value !== currentProposal.value) {
            updatedNegotiation = await makeProposal(selectedProposal.value);
        }

        const isNegotiationOpen = (updatedNegotiation ? updatedNegotiation.state : negotiation.value.state) === NegotiationState.Open;

        if (isBuyer.value && buyerStopProposalsEnabled.value && isNegotiationOpen) {
            await buyerStopProposals();
        }

        closeModal();
    } catch {}
};

// WATCHERS
watch(
    () => negotiation.value.state,
    value => isModalOpen.value && value !== NegotiationState.Open && closeModal()
);

watch(isModalOpen, (value, oldValue) => value && !oldValue && resetModal());
</script>

<template>
    <UModal v-if="auction" v-model="isModalOpen" :fullscreen="isMobile" :ui="modalUi">
        <div class="space-y-7 p-6 text-center">
            <div class="space-y-2">
                <p class="text-2xl font-bold lg:text-3xl">{{ tt('negotiation.proposalModal.title') }}</p>
                <i18n-t scope="global" tag="p" class="text-sm text-neutral-500 lg:text-base" keypath="negotiation.proposalModal.subtitle">
                    <template #countdown>
                        <AdpNegotiationCountdown :expires-at="negotiation.expiresAt" class="text-primary-500" />
                    </template>
                </i18n-t>
            </div>

            <div class="space-y-2">
                <div class="grid grid-cols-2 gap-4 text-left">
                    <div class="lg:space-y-1">
                        <p class="text-2xl font-semibold lg:text-3xl" :class="isBuyer ? 'text-neutral-500' : 'text-primary-500'">
                            {{ tn(negotiation.currentSellerProposal.amount, 'currency') }}
                        </p>
                        <p class="text-xs text-neutral-500 lg:text-sm">
                            {{ tt(isBuyer ? 'negotiation.sellerRequest' : 'negotiation.yourRequest') }}
                        </p>
                    </div>
                    <div class="lg:space-y-1">
                        <p class="text-2xl font-semibold lg:text-3xl" :class="isBuyer ? 'text-primary-500' : 'text-neutral-500'">
                            {{ tn(negotiation.currentBuyerProposal.amount, 'currency') }}
                        </p>
                        <p class="text-xs text-neutral-500 lg:text-sm">
                            {{ tt(isBuyer ? 'negotiation.yourOffer' : 'negotiation.buyerOffer') }}
                        </p>
                    </div>
                </div>

                <AdpAuctionPanelNoticeFee class="text-xs lg:text-base" :is-seller="!isBuyer" />
            </div>

            <div class="bg-primary-50 space-y-4 rounded-xl p-4 lg:p-6">
                <div>
                    <p class="text-xs font-extrabold uppercase tracking-widest text-black lg:text-sm">
                        {{ tt(isBuyer ? 'negotiation.yourOffer' : 'negotiation.yourRequest') }}
                    </p>
                    <div class="mt-3 flex items-center justify-between">
                        <UButton
                            icon="i-heroicons-minus"
                            size="lg"
                            color="white"
                            square
                            :ui="{ color: { white: { solid: 'disabled:text-gray-400 disabled:bg-gray-200' } } }"
                            :disabled="selectedProposal <= minProposal"
                            @click="onUpdateProposal(-1)"
                        />
                        <span class="text-primary text-2xl lg:text-3xl">{{ tn(selectedProposal, 'currency') }}</span>
                        <UButton
                            icon="i-heroicons-plus"
                            size="lg"
                            color="white"
                            square
                            :ui="{ color: { white: { solid: 'disabled:text-gray-400 disabled:bg-gray-200' } } }"
                            :disabled="selectedProposal >= maxProposal"
                            @click="onUpdateProposal(1)"
                        />
                    </div>
                    <UButton
                        size="lg"
                        class="p-1"
                        variant="link"
                        :label="tt('negotiation.proposalModal.resetProposal')"
                        :disabled="resetProposalDisabled"
                        @click="resetSelectedProposal"
                    />
                </div>

                <div class="flex justify-center gap-3">
                    <UButton
                        v-for="buttonData in multiStepButtonsData"
                        :key="buttonData.key"
                        color="white"
                        size="xl"
                        :label="buttonData.label"
                        :class="{ invisible: buttonData.hidden }"
                        @click="buttonData.click"
                    />
                </div>

                <div v-if="isBuyer" class="flex gap-2 text-left">
                    <UToggle v-model="buyerStopProposalsEnabled" color="green" class="lg:mt-1" />
                    <div>
                        <p class="text-sm lg:text-base">{{ tt('negotiation.proposalModal.stopProposalsLabel') }}</p>
                        <p v-if="buyerStopProposalsEnabled" class="text-xs text-neutral-500 lg:text-sm">
                            {{ tt('negotiation.proposalModal.stopProposalsDescription') }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="flex flex-col items-center gap-1">
                <UButton
                    size="xl"
                    :label="tt(isBuyer ? 'negotiation.updateOffer' : 'negotiation.updateRequest')"
                    block
                    :loading="makeProposalIsPending || buyerStopProposalsIsPending"
                    :disabled="confirmButtonDisabled"
                    @click="onMakeProposal"
                />
                <UButton size="xl" class="underline" color="white" variant="link" :label="tt('negotiation.cancel')" @click="closeModal" />
            </div>
        </div>
    </UModal>
</template>
