<script setup lang="ts">
import { useElementVisibility } from '@vueuse/core';
import type { PropType } from 'vue';

// PROPS
const { panelRef } = defineProps({
    panelRef: { type: Object as PropType<HTMLElement>, required: true },
});

// COMPOSABLES
const { tt } = useTypedI18n();
const headerHeight = getComputedStyle(document.documentElement).getPropertyValue('--header-height').trim();
const panelIsVisible = useElementVisibility(() => panelRef, headerHeight ? { rootMargin: `-${headerHeight}` } : undefined);

// METHODS
const onClick = (): void => {
    panelRef?.scrollIntoView({ behavior: 'smooth', block: 'start' });
};
</script>

<template>
    <UButton
        class="fixed bottom-7 right-4 z-50 opacity-0 drop-shadow-xl transition-opacity"
        :class="{ 'opacity-100': !panelIsVisible }"
        size="md"
        :label="tt('negotiation.backToNegotiation')"
        @click="onClick"
    >
        <template #trailing>
            <UIcon name="i-heroicons-chevron-up-20-solid" class="h-5 w-5" />
        </template>
    </UButton>
</template>
